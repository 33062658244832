import * as React from "react"
import { Helmet } from "react-helmet"
import '../styles/terms.css'
import Icons from "../components/icons.js";
import Footer from "../components/footer.js";

import HomeSVG from "../images/2.0/home.svg";

// markup
const TermsPage = ({}) => {
  const [footerVisible, setFooterVisible] = React.useState(false);
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms & Conditions - CULT&RAIN&trade;</title>
        <link rel="canonical" href="https://www.cultandrain.com/terms" />
        <meta name="description" content="The first luxury fashion brand born from the crypto universe merging NFTs with exclusive, physical redeemables. Join our Web3 revolution." />
      </Helmet>
      <div className="terms-page">
        <div className="wrapper">
          <div>
            <a href="/" className="homebtn"><img src={HomeSVG} /></a>
            <Icons footerVisible={footerVisible} mintVisible={true} />
          </div>
          <div className="terms-body">
            <h1><u>TERMS OF SERVICE</u></h1>
            <p><em>Effective: March 16, 2023</em></p>

<h2>INTRODUCTION</h2>

<p>Built for the Metaverse, merging digital scarcity and luxury fashion, CULT&RAIN Inc. (“CULT&RAIN™”, “we”, “us”, “our”) is designing the blueprint for curated collectibles certified on the blockchain. We are an incubator of legacy designers and multi-dimensional artists forging the new culture of fashion. Before entering the crypto house of fashion, please read the terms of service.</p>

<h2>1. 	ACCEPTANCE OF TERMS</h2>

<p>CULT&RAIN™ provides its services and sells its products to you, the Collector, through its website and application program interface located at www.cultandrain.com (the “Website”), subject to the following Terms of Service (the “Terms”).  Please review the following Terms that govern your use and purchase of products and services from our Website. By using or accessing the Website, you acknowledge that you have read and agree to these Terms.</p>

<p>CULT&RAIN™ may change or amend these Terms at any time at its sole and absolute discretion. We encourage you to review these Terms and to check periodically for any updates or changes.</p>

<h2>2.	ELIGIBILITY</h2>

<p>Collector must be at least thirteen (13) years old to access and use the Website; provided, if you are between the ages of thirteen (13) and eighteen (18) years old (or any greater age required to be deemed to have reached the age of majority under the applicable law of the state or jurisdiction of your primary residence) you may only access and use the Website with the prior permission of your parent or legal guardian, and you represent and warrant to us that you have such permission and that your parent or legal guardian has reviewed and discussed these terms of service with you.</p>

<p>Collector must not be located in a country that is subject to an economic sanction or embargo issued by the United States government, or that has been designated by the United States government or Interpol as a sponsor of terrorism.</p>

<h2>3.	DESCRIPTION OF SERVICES AND PRODUCTS</h2>

<p>CULT&RAIN™ is a purveyor of (a) non-fungible tokens (“NFTs”) of 3D artwork featuring sneakers, varsity jackets, and other apparel and (b) luxury sneakers, varsity jackets, and other apparel.</p>

<p>CULT&RAIN™ products displayed on the Website are available while supplies last. The prices displayed on the Website are quoted in U.S. Dollars and are valid and effective only in the United States.</p>

<h2>4.	PRODUCT AVAILABILITY</h2>

<p>Although availability may be indicated on the Website, we cannot guarantee product availability and products, nonetheless, may not be available for immediate delivery. We reserve the right, without liability or prior notice, to revise, discontinue, or cease to make available any or all products or to cancel any order. If there is any revision, discontinuance, or cessation, we reserve the right, without liability or prior notice, to refund the item and continue to process and ship any remaining items in the order.</p>

<h2>5.	TERMS OF SALE</h2>

<p><em>NFTs</em><br />CULT&RAIN™ mints and sells NFTs of 3D artwork featuring sneakers, varsity jackets, and other apparel on digital platforms from time to time. In exchange for payment, Collector is granted a CULT&RAIN™ NFT. Once an order is placed for an NFT, the sale is FINAL and cannot be cancelled, refunded or exchanged. Each purchased NFT also comes with a limited option to receive a free physical product companion packaged in custom CULT&RAIN™ packaging. The physical product companion may not have the same color scheme or depicted features as the purchase NFT. Additionally, some purchased NFTs may be matched with a specific rarity scale utility to be fulfilled by the artist associated with the NFT. Collector agrees to hold CULT&RAIN™ harmless for any failures of the artist to fulfill these bonus utilities. Furthermore, the purchase of the NFT does not grant any profit-sharing interest or equity in CULT&RAIN™, ownership of intellectual property, or any other rights.</p>

<p><em>Products</em><br />CULT&RAIN™ sells sneakers, varsity jackets, and other apparel through the Website. We reserve the right to cancel any orders subject to a restocking fee, accept all orders, or ban any user that we reasonably determine used browser automation, bots or a reservation service to purchase these products. We reserve the right to refuse bulk orders. Multiple orders placed for the same product on items limited to one (1) per customer is a violation of our policy and deprives other customers of the opportunity to purchase these products and is a cost and burden to our warehouse and web department. We reserve the right to cancel the orders or accept the multiple purchases at our sole discretion. We reserve the right to make exceptions and cancel, refund or exchange a launch product at our sole discretion.</p>

<p>Unless otherwise specified and except to the extent CULT&RAIN™ products are offered for sale in the United States through the Website. The Website and the contents thereof are displayed solely for the purpose of promoting CULT&RAIN™ products and services available in the United States and select foreign markets.</p>

<h2>6.  	FORCE MAJEURE</h2>

<p>CULT&RAIN™ shall not be considered to be in default or breach of these Terms, and shall be excused from performance or liability for damages to Collector, if and to the extent it shall be delayed in or prevented from performing or carrying out any of the provisions of these Terms, arising out of or from any act, omission, or circumstance by or in consequence of any act of God, cryptocurrency or NFT market crash, smart contract failure, computer hack, DDoS attack, pandemic, labor strike, sabotage, failure of suppliers of materials, war, invasion, insurrection, riot, fire, storm, flood, ice, earthquake, explosion, epidemic, breakage or accident to machinery or equipment or any other cause or causes beyond CULT&RAIN™’s reasonable control, including any curtailment, order, regulation, or restriction imposed by governmental, military or lawfully established civilian authorities. Any party claiming a Force Majeure event shall use reasonable diligence to remove the condition that prevents performance and shall not be entitled to suspend performance of its obligations in any greater scope or for any longer duration than is required by the Force Majeure event. CULT&RAIN™ shall use its best efforts to mitigate the effects of such Force Majeure event, remedy its inability to perform, and resume full performance of its obligations hereunder.</p>

<h2>7. 	PAYMENT</h2>

<p><em>NFTs</em><br />Collector must link a Web3 Wallet (MetaMask, Fortmatic, WalletConnect) to the Website and use Ethereum (ETH) cryptocurrency from your Web3 Wallet to complete your purchase.</p>

<p>CULT&RAIN™ will have no insight into or control over these payments or transactions, nor do we have the ability to reverse any transactions. With this in mind, CULT&RAIN™ will have no liability to you or to any third party for any claims or damages that may arise as a result of any transactions that you engage in via the Website, or using the smart contracts, or any other transactions that you conduct via the Ethereum network or a Web3 Wallet.</p>

<p>Collector is responsible for any sales, use, value-added or other governmental taxes, fees or duties due with respect to your order.</p>

<p>Collector represents and warrants that any purchase of an NFT by you is solely for your personal collection, use and enjoyment, and not for speculative or investment purposes, or for use as a substitute for currency or other medium of exchange.</p>

<p><em>Products</em><br />We accept payments for products by Credit Card (Visa, Mastercard, American Express, Discover, Diners Club), PayPal and Apple Pay.</p>

<p>Upon receiving your order, we conduct a standard pre-authorization check on your payment card to ensure there are sufficient funds to fulfill the transaction. The pre-authorization is completed once the order has been confirmed and your payment card will be charged in full at that point of transaction. If the billing or shipping information is incorrect or incomplete, we will contact you to confirm it before your order is shipped.</p>

<p><em>Credit Card Permission by Parent, Guardian or Third Party</em><br />By using this Website, adding items to a shopping cart and checking the "agree" boxes prior to check out you warrant and represent that you have discussed the purchase with your parent, guardian or third party whose credit card is being used to purchase the items.  You warrant and represent that you have received permission, approval or consent from your parent, guardian or third party to purchase the items with their credit card and you, your parent, guardian or third party will not claim the purchase was not authorized.</p>

<h2>8.	OWNERSHIP</h2>

<p>CULT&RAIN™ owns the name and mark and other intellectual property and proprietary technologies that constitute the brand image by which CULT&RAIN™ is known to the public. Intellectual property and proprietary technologies owned by CULT&RAIN™, include, but are not limited to: CULT&RAIN™ smart contracts, logos, trademarks, trade dress, product names, service marks, and copyrightable works, including but not limited to content of its internet sites, user interface, metadata, and sneaker skins, as it relates to CULT&RAIN™. Unless otherwise noted, all materials, including images, illustrations, designs, icons, photographs, video clips, and written and other materials that appear as part of the Website are intellectual property owned or licensed by CULT&RAIN™.</p>

<h2>9.	COLLECTOR’S RIGHTS</h2>

<p>CULT&RAIN™ grants to the Collector a worldwide non-exclusive, assignable, perpetual, and royalty-free license to display the NFT solely for non-commercial purposes, given that such display right may only be assigned to a subsequent Collector of the NFT simultaneously with the transfer of ownership of the NFT and these Terms.</p>

<h2>10.	RESALE</h2>

<p>By purchasing a CULT&RAIN™ NFT, the Collector acknowledges and agrees that these Terms are binding on subsequent CULT&RAIN™ NFT collectors and any succeeding collectors, causing all collectors of the purchased CULT&RAIN™ NFT to be subject to these Terms.</p>

<h2>11.	RULES OF CONDUCT</h2>

<p>As a part of using the CULT&RAIN™ website, Collector agrees to not:</p>

<ul>
<li>Modify, translate, reverse engineer, decompile, disassemble or create derivative works based on any software or artwork created by CULT&RAIN™.</li>
<li>Hack, crack, phish, SQL inject, or otherwise threaten or actually harm the security of our systems or those of other collectors.</li>
<li>Infringe on the intellectual property rights of CULT&RAIN™ and its artists.</li>
<li>Do anything that infringes on the rights of CULT&RAIN™ or a third party.</li>
</ul>

<p>The use of any robot, spider, or other automatic device or software (“Bot”) in connection with the services is strictly prohibited. In the event we reasonably determine that any order or attempted order was made by or through use of any robot or other automatic device or software, we have the right, in our sole and absolute discretion, to deny or cancel any such order, refuse to accept any returns or offer refunds of any or all purchases made by Bots and/or charge a 20% restocking fees for cancelled or returned items. Multiple orders by the same customer within seconds is compelling evidence of the use of a Bot.</p>

<h2>12.	ASSUMPTION OF RISK</h2>

<p>Website transactions, including but not limited to primary sales, secondary market sales, listings, offers, bids, acceptances, and other operations utilize experimental smart contract and blockchain technology, including NFTs, cryptocurrencies, consensus algorithms, and decentralized or peer-to-peer networks and systems. Collector acknowledges and agrees that such technologies are experimental, speculative, and inherently risky and may be subject to bugs, malfunctions, timing errors, hacking and theft, or changes to the protocol rules of the Ethereum blockchain (i.e., “forks”) which can adversely affect the smart contracts and may expose you to a risk of total loss, forfeiture of your cryptocurrency or NFT, or lost opportunities to buy or sell NFTs.</p>

<h2>13.	DISCLAIMER OF WARRANTIES</h2>

<p>THIS WEBSITE AND ITS CONTENTS ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. BY USING THIS WEBSITE, COLLECTOR ACCEPTS SOLE RESPONSIBILITY FOR ANY AND ALL TRANSACTIONS INVOLVING CULT&RAIN™ NFT COLLECTIBLES OR PRODUCTS. YOU ACKNOWLEDGE THAT YOUR USE OF THE WEBSITE IS AT YOUR SOLE RISK, THAT YOU ASSUME FULL RESPONSIBILITY FOR ALL COSTS ASSOCIATED WITH ALL NECESSARY SERVICING OR REPAIRS OF ANY EQUIPMENT YOU USE IN CONNECTION WITH YOUR USE OF OUR SITE, AND THAT CULT&RAIN™ SHALL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND RELATED TO YOUR USE OF THIS WEBSITE. COLLECTOR ACKNOWLEDGES THAT ANY PHYSICAL REDEEMABLES OR UTILITIES ISSUED IN CONNECTION WITH A CULT&RAIN™ NFT ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, AND THAT SUCH PHYSICAL REDEEMABLES OR UTILITIES MAY NOT BE EXACT REPLICAS OF THE CULT&RAIN™ NFT, INCLUDING WITH RESPECT TO COLOR OR DEPICTED FEATURES.</p>

<h2>14.	LIMITATION OF LIABILITY</h2>

<p>COLLECTOR AGREES TO HOLD US, OUR AFFILIATED AND RELATED COMPANIES, AND EACH OF THEIR RESPECTIVE DIRECTORS, SHAREHOLDERS, OFFICERS, EMPLOYEES, CONTRACTORS, REPRESENTATIVES, LICENSEES AND ASSIGNS, HARMLESS FROM ANY LOSSES, DAMAGES, CLAIMS, COSTS AND EXPENSES YOU MAY INCUR (A) AS A CONSEQUENCE OF MINTING (OR ATTEMPTING TO MINT) YOUR NFT OR (B) YOUR USE OF THE WEBSITE.</p>

<h2>15.	DISPUTE RESOLUTION</h2>

<p>THESE TERMS REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS. COLLECTOR AGREES TO WAIVE ANY CLASS ACTION STATUS, CLASS ARBITRATION, OR ANY OTHER TYPE OF REPRESENTATIVE PROCEEDING. IF A DISPUTE BETWEEN CULT&RAIN™ AND COLLECTOR ARISES, THEN THE DISPUTE SHALL BE DETERMINED AND RESOLVED BY BINDING ARBITRATION IN MANHATTAN, NEW YORK IN ACCORDANCE WITH THE COMMERCIAL RULES OF ARBITRATION (“RULES”) OF THE JUDICIAL ARBITRATION AND MEDIATION SERVICES (“JAMS”) BEFORE ONE ARBITRATOR APPLYING THE LAWS OF THE STATE OF NEW YORK.  THE ARBITRATOR SHALL BE SELECTED BY THE PROCEDURES PRESCRIBED BY THE JAMS RULES. ANY AWARD RENDERED BY THE ARBITRATOR SHALL BE FINAL AND BINDING UPON CULT&RAIN™ AND COLLECTOR, AND JUDGMENT THEREOF MAY BE ENTERED IN ANY COURT HAVING JURISDICTION THEREOF. THE COSTS SHALL BE BORNE BY THE PARTY THAT INITIATES ARBITRATION.</p>

<h2>16. 	PRIVACY</h2>

<p>For information about how we collect, use, share or otherwise process information about you, please see our Privacy Policy available at cultandrain.com/privacy.</p>

<h2>17.	CULT&RAIN™ COMMUNICATIONS TO YOU</h2>

<p>You agree that CULT&RAIN™ may send electronic mail to you for the purpose of advising you of changes or additions to this site, about any of CULT&RAIN™ products or services, or for such other purpose(s) as CULT&RAIN™ deems appropriate.</p>

<h2>18.	SEVERABILITY</h2>

<p>If any provision hereof is held invalid or unenforceable by a court of competent jurisdiction, such invalidity shall not affect the validity or operation of any other provision and such invalid provision shall be deemed to be severed from the Terms.</p>

<h2>19.	GOVERNING LAW</h2>

<p>These Terms and any dispute or claim relating in any way to your use of our Website, or to any services offered or products sold by us will be governed solely by the laws of State of New York without regard to its conflicts of laws provisions that would cause the application of the laws of any other jurisdiction. Any dispute between CULT&RAIN™ and Collector that is not subject to arbitration will be resolved in the state or federal courts located in the Borough of Manhattan, in the State of New York. Collector understands and agrees that your use of the Website as contemplated by these Terms shall be deemed to have occurred in New York State. Collector consents to the jurisdiction of such courts, agrees to accept service of process by mail, and waives any jurisdictional or venue defenses otherwise available.</p>
          </div>
          <Footer setFooterVisible={setFooterVisible} />
        </div>
      </div>
    </main>
  )
}

export default TermsPage
